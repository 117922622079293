.columnsLines {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	pointer-events: none;
	overflow: hidden;
	display: flex;
	flex-direction: column;


	&__items {
		// bottom: 0;
		column-gap: 1px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		// left: 0;
		// position: absolute;
		// right: 0;
		// top: 0;
		width: 100%;
		flex-grow: 1;

		@include media($columnsBreakpoint) {
			grid-template-columns: repeat(6, 1fr);
		}
	}


	&__item {
		outline: 1px solid $colorBorder;

		&:nth-child(n + 3) {
			display: none;

			@include media($columnsBreakpoint) {
				display: block;
			}
		}
	}
}

