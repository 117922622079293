.logo {
	line-height: 0;
	display: inline-block;
	width: auto;
	height: auto;
	padding: 0;
	text-transform: uppercase;


	&__name {
		@include font(logo);
		display: inline-block;
		padding-left: $base3;
		position: relative;


		&::before {
			background-color: currentColor;
			border-radius: 50%;
			content: '';
			position: absolute;
			left: 0;
			width: $base2;
			height: $base2;
			top: 50%;
			margin-top: -$base;
		}
	}


	svg {
		width: 100%;
		height: auto;
	}
}
