.cover {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	// grid-template-rows: repeat(1, minmax(#{(100% / 3)}, max-content));
	gap: 1px;
	margin-top: $headerHeight;
	overflow: hidden;
	position: relative;
	min-height: calc(#{(100vh / 3)} - #{$headerHeight});

	@include media($columnsBreakpoint) {
		grid-template-columns: repeat(6, 1fr);
		margin-top: $headerHeightLarge;
		min-height: calc(#{(100vh / 3)} - #{$headerHeightLarge});
	}

	&--home {
		grid-template-columns: repeat(3, 1fr);
		// grid-template-rows: repeat(3, 1fr);
		grid-template-rows: repeat(3, minmax(#{(100% / 3)}, max-content));
		min-height: calc(100vh - #{$headerHeight});

		@include media($columnsBreakpoint) {
			grid-template-columns: repeat(6, 1fr);
			min-height: calc(100vh - #{$headerHeightLarge});
		}
	}

	&--research {
		grid-template-columns: 1fr 2fr;
		grid-template-rows: minmax(#{(100vh / 3)}, min-content)  minmax(#{(100vh / 3)}, min-content);
		min-height: calc(#{(100vh / 3 * 2)} - #{$headerHeight});

		@include media($columnsBreakpoint) {
			grid-template-columns: repeat(6, 1fr);
			grid-template-rows: minmax(#{(100vh / 6)}, min-content)  minmax(#{(100vh / 6)}, min-content);
			min-height: calc(#{(100vh / 3)} - #{$headerHeightLarge});
		}
	}

	&--search {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		min-height: calc(#{(100vh / 3)} - #{$headerHeight});

		@include media($columnsBreakpoint) {
			grid-template-columns: repeat(6, 1fr);
			grid-template-rows: 1fr 1fr;
			min-height: calc(#{(100vh / 3 * 2)} - #{$headerHeightLarge});
		}
	}



	&--publications,
	&--events,
	&--persons {
		grid-template-columns: 1fr 2fr;
		grid-template-rows: minmax(#{(100vh / 3)}, min-content)  minmax(#{(100vh / 3)}, min-content);
		min-height: calc(#{(100vh / 3 * 2)} - #{$headerHeight});

		@include media($columnsBreakpoint) {
			grid-template-columns: repeat(6, 1fr);
			grid-template-rows: minmax(#{(100vh / 6)}, min-content)  minmax(#{(100vh / 6)}, min-content)  minmax(#{(100vh / 3)}, #{(100vh / 3)});
			min-height: calc(#{(100vh / 3 * 2)} - #{$headerHeightLarge});
		}
	}


	&__preview {
		display: none;

		@include media($columnsBreakpoint) {
			display: flex;
			grid-column-start: var(--startCol, auto);
			grid-row-start: var(--startRow, auto);
		}
	}


	&__description {
		@include font(coverDescription);
		background-color: $colorBgBase;
		display: flex;
		flex-direction: column;
		grid-column: 2 / 3;
		grid-row: 2 / 3;
		margin-top: -1px;
		padding: $base;
		position: relative;
		margin-left: 1px;
		margin-right: 1px;

		@include media($columnsBreakpoint) {
			grid-column: 3 / 7;
			grid-row: 2 / 3;
			justify-content: flex-end;
		}


		&::after {
			border-top: 1px solid $colorBorder;
			content: '';
			pointer-events: none;
			position: absolute;
			right: 0;
			top: 0;
			width: 100vw;

			@include media($columnsBreakpoint) {
				top: auto;
				bottom: 0;
			}

			.cover--research & {
				@include media($columnsBreakpoint) {
					display: none;
				}
			}
		}
	}


	&__info {
		@include font(category);
		padding: $base;

		@include media($columnsBreakpoint) {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}
	}


	&__search {
		background-color: $colorBgBase;
		position: relative;

		@include media($columnsBreakpoint) {
			@include app-rowLines();
			grid-column: 3 / 7;
			grid-row: 2 / 3;

		}
	}


	&__subtitle {
		@include font(coverSubtitle);
		@include app-rowLines();
		grid-column: 1 / 2;
		grid-row: 2 / 3;
		padding: $base;
		position: relative;
	}


	&__title {
		@include font(coverTitle);
		grid-column: 1 / 2;
		padding: $base;

		@include media($columnsBreakpoint) {
			grid-column: 2 / 5;
		}

		.cover--search & {
			display: none;
		}

		.cover--home & {
			grid-column: 1 / 4;
			grid-row: 1 / 2;

			@include media($columnsBreakpoint) {
				grid-column: 1 / 7;
			}
		}

		.cover--research &,
		.cover--publications &,
		.cover--events &,
		.cover--persons & {
			grid-column: 2 / 3;
			grid-row: 1 / 2;
			background-color: $colorBgBase;
			margin-left: 1px;
			margin-right: 1px;

			@include media($columnsBreakpoint) {
				grid-column: 3 / 7;
			}
		}
	}
}
