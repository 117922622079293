
@mixin app-blockPadding($baseClass: '.block', $nestedSelector: ' > &', $customFirstLine: true, $onlyDirs: ()) {
	$dirs: (
		reset: (left, right, top, bottom),
		horizontal: (left, right),
		vertical: (top, bottom),
		firstLine: (top)
	);

	@each $dir, $spots in $dirs {
		@if length($onlyDirs) == 0 OR map-get($onlyDirs, $dir) {
			@each $spot in $spots {
				$values: map-get($blockPaddingValues, $dir);
				@each $option, $optionValues in $values {
					$property: 'padding-' + $spot;
					$modifier: 'padding' + ucFirst($spot) + ucFirst($option);
					$selector: $baseClass + '--' + $modifier + $nestedSelector;
					$isMulti: str-slice($option, 1, 5) == 'multi';

					@if $isMulti {
						$selector: $baseClass + $selector;
					}

					@if $dir == firstLine AND $customFirstLine {
						@if $isMulti {
							// $selector: '[data-layout-index="0"] .layout__column > ' + $baseClass + $baseClass + '--' + $modifier + ':first-child' + $nestedSelector;
							$selector: $baseClass + '--visuallyFirstMulti' + $baseClass + '--' + $modifier + $nestedSelector;
						} @else {
							// $selector: '[data-layout-index="0"] [data-column-index="0"] > ' + $baseClass + '--' + $modifier + ':first-child' + $nestedSelector;
							$selector: $baseClass + '--visuallyFirst' + $baseClass + '--' + $modifier + $nestedSelector;
						}
					}

					#{$selector} {
						@include mediaMap($values, $option, $property);
					}

					// @if $dir == firstLine AND $customFirstLine {
					// 	$selector: '[data-layout-index="0"] ' + $baseClass + '--' + $modifier + ':first-child' + $nestedSelector;

					// 	@include media($columnsBreakpoint) {
					// 		#{$selector} {
					// 			@include mediaMap($values, $option, $property);
					// 		}
					// 	}
					// }
				}
			}
		}
	}
}
