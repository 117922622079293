.block {
	@include app-blockBorders();
	@include app-colorSchemes();
	@include app-blockFixedMinHeight();
	display: grid;
	flex-grow: 1;
	position: relative;

	// .layout--drawColumnsLines & {
	// 	@include media($columnsBreakpoint) {
	// 		margin-left: 1px;
	// 		margin-right: 1px;
	// 	}
	// }

	&--hAlignBegin {
		text-align: left;
	}

	&--hAlignEnd {
		text-align: right;
	}

	&--hAlignCenter {
		text-align: center;
	}

	&--hAlignJustified {
		text-align: justify;
	}

	@include media($columnsBreakpoint) {
		flex-grow: var(--inColumnGrowFactor, 1);
	}

	.layout__innerGridItem > & {
		flex-grow: 1;

		@include media($columnsBreakpoint) {
			flex-grow: 1;
		}
	}

	&--breadcrumbs.block--breadcrumbsModeFloating {
		pointer-events: none;
	}

	&--slide {
		grid-column: 1 / 2;
		grid-row: 1 / 2;

		.slideshow--animationTypeFade & {
			@include ease(opacity visibility, $slideshowFadeDuration);
			@include fadeOut();
			z-index: 1;

			&.js-current {
				@include fadeIn();
				z-index: 2;
			}
		}

		.slideshow--animationTypeSlide .slideshow__slides.js-animate & {
			@include ease(transform, $slideshowSlideDuration);
		}

		.slideshow--animationTypeSlide.slideshow--circular & {
			transform: translateX(calc(100% * var(--slideBalancedPosition)));
		}

		.slideshow--animationTypeSlide:not(.slideshow--circular) & {
			transform: translateX(calc(100% * var(--slidePosition)));
		}
	}

	&--submenu {
		&.block--colorSchemeInverted {
			background-color: $colorBgSubmenu;
		}
	}


	// used to set min height with ratios and vh size
	&::before {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		content: '';
		display: block;
		width: 100%;
		// padding-bottom value set by a mixin in the block
	}


	&__container {
		@include app-blockPadding();
		color: inherit;
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		display: flex;
		flex-grow: 0;
		flex-shrink: 0;
		flex-direction: column;
		order: 2;
		padding: 0; //$blockVerticalPaddingBaseSmall $blockHorizontalPaddingBaseSmall;
		position: relative;
		width: 100%;
		text-decoration: none;
		z-index: 1;


		.block--hPositionBegin > & {
			align-items: flex-start;
		}

		.block--hPositionCenter > & {
			align-items: center;
		}

		.block--hPositionEnd > & {
			align-items: flex-end;
		}

		.block--vAlignBegin > &,
		.block--vAlignGapBeforeContent > &,
		.block--vAlignGapAfterContent > &,
		.block--vAlignGapAroundContent > & {
			justify-content: flex-start;
		}

		.block--vAlignEnd > & {
			justify-content: flex-end;
		}

		.block--vAlignCenter > & {
			justify-content: center;
		}


		.block--productDownloadWizard & {
			overflow: hidden;
			position: relative;

			.body.js-productDownloadWizardModal & {
				overflow: visible;
			}
		}
	}


	&__content {
		// @include app-container();
		@include app-blockHorizontalSize();
		display: flex;
		flex-direction: column;
		order: 4;
		position: relative;
		width: 100%;
		z-index: 1;

		.block--previewLayouts & {
			// position: relative;
			overflow: hidden;
		}


		.block--hSizeFullNoContentPadding > .block__container > & {
			@include app-neutralizeContentPadding();
		}

		.block--vAlignGapBeforeContent > .block__container > & {
			justify-content: flex-end;
			flex-grow: 1;
		}

		.block--vAlignGapAfterContent > .block__container > & {
			flex-grow: 1;
		}

		.block--vAlignGapAroundContent > .block__container > & {
			flex-grow: 1;
		}

		* + & {
			margin-top: 0; //$base * 2.4;

			// @include media($mediumBreakpoint) {
			// 	margin-top: $base * 4.8;
			// }
		}

		.block--slideshow.block--vSizeFixed > .block__container > & {
			flex-grow: 1;

			@include media($columnsBreakpoint) {
				flex-grow: 0;
			}
		}

		.block--slideshow.block--vSizeMultiFixed > .block__container > & {
			@include media($columnsBreakpoint) {
				flex-grow: 1;
			}
		}

		.block--productModels & {
			margin-top: 0;

			@include media($mediumBreakpoint) {
				margin-top: 0;
			}
		}

		.block--twoContents & {
			display: grid;
			grid-template-rows: auto auto;
			grid-template-columns: 100%;
			grid-template-areas:
				"figure"
				"text";

			@include media($columnsBreakpoint) {
				grid-template-rows: auto;
				grid-template-areas: "figure	text";
				grid-template-columns: 1fr 1fr;
			}
		}

		.block--twoContents.block--twoContentsOrderInverted & {
			grid-template-areas:
				"text"
				"figure";
		}

		.block--twoContents.block--twoContentsOrderMultiInverted & {
			@include media($columnsBreakpoint) {
				grid-template-areas: "text		figure";
			}
		}

		$map: map-get($twoContentsGaps, vertical);
		@each $name, $values in $map {
			$selector: '.block--twoContents.block--twoContentsVerticalGap' + ucFirst($name) + ' &';

			#{$selector} {
				@include mediaMap($map, $name, row-gap);
			}
		}

		$map: map-get($twoContentsGaps, horizontal);
		@each $name, $values in $map {
			$selector: '.block--twoContents.block--twoContentsHorizontalGap' + ucFirst($name) + ' &';

			#{$selector} {
				@include mediaMap($map, $name, column-gap);
			}
		}


		.block--coverHome & {
			min-height: 80vh;
			// background-color: red;
		}
	}


	&__bg {
		flex-grow: 0;
		flex-shrink: 0;
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		height: auto;
		left: 0;
		order: 1;
		top: 0;
		width: 100%;
		z-index: 0;
		display: flex;
		flex-direction: column;


		&--video {
			overflow: hidden;
		}

		.block--vSizeAuto > &,
		.block--vSizeFixed > & {
			height: 100%;
			position: absolute;

			@include media($columnsBreakpoint) {
				height: auto;
				position: relative;
			}
		}

		.block--vSizeMultiAuto > &,
		.block--vSizeMultiFixed > & {
			@include media($columnsBreakpoint) {
				height: 100%;
				position: absolute;
			}
		}
	}


	&__header {
		@include app-blockHorizontalSize();
		display: flex;
		flex-direction: column;
		width: 100%;
		z-index: 1;

		.block--vAlignGapAroundContent > .block__container > & {
			flex-grow: 1;
		}


		.block--stickyTitle & {
			background-color: $colorBgBase;
			border-bottom: 1px solid $colorBorder;
			left: 0;
			position: sticky;
			top: $headerHeight;
			z-index: 3;

			@include media($columnsBreakpoint) {
				top: $headerHeightLarge;
			}
		}
	}
}
