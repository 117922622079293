.image {
	@include ease(opacity visibility, $duration2);
	max-width: 100%;

	&[data-component] {
		@include fadeOut();
	}

	&.js-loaded,
	html.no-js & {
		@include fadeIn();
	}

	.imageWrapper > & {
		height: auto;
		width: 100%;
	}

	.imageWrapper--withRatio > & {
		// height: 100%;
		left: 0;
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
	}


	.imageWrapper--mosaic & {
		@include objectFit(cover);
		left: 0;
		height: 100%;
		position: absolute;
		top: 0;
	}

	.figure--preview & {
		position: relative;
		height: auto;

		.preview:not(.preview--person) & {
			@include media($columnsBreakpoint) {
				@include objectFit(cover);
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
	}

	.figure--blurred & {
		@include ease(filter, $duration4);
		user-select: none;
		filter: blur($imageBlurRadius);
		transform: scale($imageSmallBlurredScale) translateZ(0);
		backface-visibility: hidden;
	}

	.figure--blurred.js-toggled & {

		// transform: scale(1);
		filter: blur(0);
	}

	.figure--bg &,
	&--bg {
		@include objectFit(cover);
		user-select: none;
		filter: blur($imageBlurRadius);
		transform: scale($imageBlurredScale);
	}

	.figure--bg .imageWrapper:not(.imageWrapper--withRatio) & {
		height: 100%;
	}

	&--videoCover {
		@include ease(opacity visibility, $duration2);
		@include objectFit(cover);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.video.js-loading & {
			@include fadeOut();
		}

		.video.js-loaded & {
			display: none;
		}
	}


	// .gallery:not(.gallery--layoutTypeJustified) & {
	// 	@include objectFit(cover);
	// }

	// .preview & {
	// 	@include objectFit(cover);
	// }
}
