.bookingModal {
	@include fadeOut($duration4);
	background-color: $colorBgModal;
	display: none;
	left: 0;
	position: relative;
	right: 0;
	top: 0;
	z-index: 3;

	&.js-fixed {
		position: fixed;
	}

	&.js-beforeActive {
		display: block;
		will-change: opacity, visibility;
	}

	&.js-duringActive {
		@include fadeIn();
	}


	&__content {
		@include app-container();
		padding-bottom: $blockVerticalSpacing;
		padding-top: $firstBlockTopSpacing;
	}
}
