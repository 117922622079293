.layout {
	position: relative;
	z-index: calc(var(--layoutsAmount, 100) - var(--layoutIndex, 1));

	@mixin stickyLayout() {
		top: 0;
		position: sticky;
	}

	@include grid($gridColumnsSmall);


	&--drawColumnsLines {
		column-gap: 1px;
	}

	.block--previewLayouts & {
		column-gap: 1px;
		// outline: 1px solid $colorBorder;
	}

	& + & {
		.block--previewLayouts & {
			margin-top: 1px;
		}
	}

	&--sticky {
		@include stickyLayout();

		&:not(.layout--stickyMedium) {
			@include media($mediumBreakpoint) {
				position: relative;
			}
		}

		&:not(.layout--stickyMulti) {
			@include media($columnsBreakpoint) {
				position: relative;
			}
		}
	}

	&--stickyMedium {
		@include media($mediumBreakpoint) {
			@include stickyLayout();
		}

		&:not(.layout--stickyMulti) {
			@include media($columnsBreakpoint) {
				position: relative;
			}
		}
	}

	&--stickyMulti {
		@include media($columnsBreakpoint) {
			top: 0;
			position: sticky;
		}
	}

	&--layoutWidthContent {
		@include media($columnsBreakpoint) {
			@include app-container();
			margin-left: auto;
			margin-right: auto;
		}
	}

	@include media($columnsBreakpoint) {
		@include grid($gridColumnsLarge);
	}


	&__column {
		display: flex;
		flex-direction: column;
		grid-column-start: span $gridColumnsSmall;

		@include media($columnsBreakpoint) {
			@include gridColumnsModifiers();
			order: var(--multiColumnsIndex, 1);
		}
	}


	&__innerGridItem {
		@include media($columnsBreakpoint) {
			display: flex;
			flex-direction: column;
			grid-column-start: var(--colStart);
			grid-column-end: span var(--colSpan);
			grid-row-start: var(--rowStart);
			grid-row-end: span var(--rowSpan);
		}
	}
}
