.figure {
	max-width: 100%;

	&--listLink {
		flex-grow: 1;
	}

	&--preview {
		position: relative;
		width: 100%;

		.preview:not(.preview--person) & {
			@include media($columnsBreakpoint) {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	&--bg {
		height: 100%;
		user-select: none;
		width: 100%;
		display: block;
		overflow: hidden;

		.block--vSizeBg > .block__bg & {
			height: auto;
			flex-grow: 1;

			@include media($columnsBreakpoint) {
				height: 100%;
			}
		}

		.block--vSizeMultiBg > .block__bg & {
			@include media($columnsBreakpoint) {
				height: auto;
				flex-grow: 1;
			}
		}

		.block__bg--video & {
			@include ease(opacity visibility, $duration5);
		}

		.block__bg--video.js-started & {
			@include fadeOut();
		}
	}

	.block--twoContents &--content {
		grid-area: figure;
	}


	// slx specific
	&--altBg {
		@include ease(opacity visibility, $duration4);
		@include fadeOut();
		height: 100%;
		left: 0;
		position: absolute;
		backface-visibility: hidden;
		top: 0;
		width: 100%;
		z-index: 2;


		@include onHover('.listTile--withAltBg', ' &') {
			@include fadeIn();
		}
	}


	&::before {
		width: $base2;
		border-bottom: 1px solid #FFFFFF;
		height: 0;
		right: $base;
		top: $base * 1.5;
		margin-top: -0.5px;
	}


	&::after {
		@include ease(filter transform, $duration4);
		display: none;
		width: $base;
		height: $base;
		border-radius: 50%;
		background-color: #FFFFFF;
		top: $base;
		right: $base2;
		backface-visibility: hidden;
		filter: blur(1px);
		transform: translateX(-1px);
	}

	&.js-toggled::after {
		transform: translateX(12px);
		filter: blur(0);
	}


	&--blurred::before,
	&--blurred::after {
		display: block;
		content: '';
		position: absolute;
		z-index: 3;
	}



	&__caption {
		@include font(caption);
		margin-top: $base;

		// .blocks:not(.blocks--nested) > .block--image.block--hSizeFull & {
		// 	@include app-container();
		// }

		.figure--bg & {
			bottom: 0;
			left: 0;
			padding: $base;
			position: absolute;
			text-align: right;
			width: 100%;
		}
	}
}
