.intlSelector {
	@mixin linkUnderline() {
		@include ease(transform, $duration2);
		border-top: 1px solid currentColor;
		bottom: -($base / 4);
		content: '';
		left: 0;
		position: absolute;
		transform: translateY(1px) scaleX(0);
		transform-origin: 0 0;
		width: 100%;
	}

	@include font(base);
	@include fadeOut($duration4);
	color: $colorInverted;
	display: none;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	left: 0;
	// padding-top: ($headerHeight + $base5);
	position: relative;
	right: 0;
	top: 0;
	z-index: 5;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;


	&.js-fixed {
		position: fixed;
	}

	&.js-beforeActive {
		display: flex;
		will-change: opacity, visibility;
	}

	&.js-duringActive {
		@include fadeIn();
	}


	&::before {
		background-color: transparentize($colorBgInverted, 0.2);
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		content: '';
		position: fixed;
		z-index: 0;
	}


	&__content {
		background-color: $colorBgInverted;
		max-width: 100%;
		position: relative;
		width: $base * 48;
		z-index: 2;
		padding: 0 $base2 $base2 $base2;

		// padding-bottom: $headerHeight;
		// padding-left: $blockHorizontalPaddingDefaultSmall;
		// padding-right: $blockHorizontalPaddingDefaultSmall;

		// @include media($columnsBreakpoint) {
		// 	padding-left: $blockHorizontalPaddingDefaultLarge;
		// 	padding-right: $blockHorizontalPaddingDefaultLarge;
		// }
	}


	&__lang {
		text-transform: uppercase;
		text-align: left;
		width: $base * 2.5;

		& + & {
			margin-left: $base * 2.5;
		}
	}


	&__langLink {
		color: currentColor;
		display: inline-block;
		position: relative;
		text-decoration: none;

		.intlSelector__lang--current & {
			font-weight: bold;
			pointer-events: none;
		}


		&::before {
			@include linkUnderline();
		}

		@include onHover('&', '::before') {
			transform: translateY(1px) scaleX(1);
		}

		@include onHover('.intlSelector__regionLink', ' + .intlSelector__langs .intlSelector__lang--default &::before') {
			transform: translateY(1px) scaleX(1);
		}
	}


	&__langs {
		display: flex;
		flex-direction: row;
		flex-shrink: 0;
		justify-content: flex-start;
		width: $base * 17.5;
	}


	&__region {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		& + & {
			border-top: 1px solid $colorBorder;
			margin-top: ($base / 4);
			padding-top: ($base / 4);
		}
	}


	&__regionLink {
		color: currentColor;
		display: inline-block;
		position: relative;
		flex-grow: 0;
		flex-shrink: 1;
		text-decoration: none;

		.intlSelector__region--current & {
			font-weight: bold;
			pointer-events: none;
		}


		&::before {
			@include linkUnderline();
		}

		@include onHover('&', '::before') {
			transform: translateY(1px) scaleX(1);
		}
	}


	&__title {
		@include font(base);
		background-color: $colorBgInverted;
		padding-bottom: $base2;
		position: sticky;
		top: 0;
		padding-top: $base2;
		left: 0;
		width: 100%;
		font-weight: bold;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 2;
	}
}



// 	&__close {
// 		position: relative;
// 		position: sticky;
// 		right: $base;
// 		top: $base;
// 	}


// 	&__container {
// 		background-color: $colorBgBase;
// 		max-width: 100%;
// 		padding: $base;
// 		position: relative;
// 		width: $base * 30;
// 	}


// 	&__lang {
// 		text-transform: uppercase;
// 		text-align: left;
// 		width: $base * 1.5;

// 		& + & {
// 			margin-left: $base * 1.5;
// 		}
// 	}


// 	&__langs {
// 		display: flex;
// 		flex-direction: row;
// 		flex-shrink: 0;
// 		justify-content: flex-start;
// 		width: $base * 10.5;
// 	}


// 	&__langLink {
// 		color: currentColor;
// 		display: inline-block;
// 		position: relative;
// 		text-decoration: none;

// 		.intlSelector__lang--current & {
// 			font-weight: bold;
// 			pointer-events: none;
// 		}


// 		&::before {
// 			@include linkUnderline();
// 		}

// 		@include onHover('&', '::before') {
// 			transform: translateY(1px) scaleX(1);
// 		}

// 		@include onHover('.intlSelector__regionLink', ' + .intlSelector__langs .intlSelector__lang--default &::before') {
// 			transform: translateY(1px) scaleX(1);
// 		}
// 	}


// 	&__region {
// 		display: flex;
// 		flex-direction: row;
// 		justify-content: space-between;

// 		& + & {
// 			border-top: 1px solid $colorRegionBorder;
// 			margin-top: ($base / 4);
// 			padding-top: ($base / 4);
// 		}
// 	}


// 	&__regions {
// 		.intlSelector__title + & {
// 			margin-top: $base;
// 		}
// 	}


// 	&__regionLink {
// 		color: currentColor;
// 		display: inline-block;
// 		position: relative;
// 		flex-grow: 0;
// 		flex-shrink: 1;
// 		text-decoration: none;

// 		.intlSelector__region--current & {
// 			font-weight: bold;
// 			pointer-events: none;
// 		}


// 		&::before {
// 			@include linkUnderline();
// 		}

// 		@include onHover('&', '::before') {
// 			transform: translateY(1px) scaleX(1);
// 		}
// 	}


// 	&__title {
// 		@include font(tinyTitle);
// 		font-weight: bold;
// 		padding-right: $base2;
// 	}
// }
