.imageWrapper {
	display: block;
	max-width: 100%;
	position: relative;

	// variables set inline in the style attribute;
	&--withRatio {
		@include minAspectRatio(var(--heightRatioPerc));
		// overflow: hidden;
		align-items: stretch;
	}

	&--mosaic {
		@include minAspectRatio($mosaicFigureRatio);
		// min-height: 100%;
		// overflow: hidden;
	}

	.figure--preview & {
		overflow: hidden;
		position: relative;

		.preview:not(.preview--person) & {
			@include media($columnsBreakpoint) {
				left: 0;
				top: 0;
				position: absolute;
				width: 100%;
				height: 100%;
			}
		}
	}

	.figure--blurred & {
		// this contrasts the whiteish effect of the blur on the edges
		box-shadow: inset 0 0 100px 0 transparentize($colorBase, 0.5);
	}


	.figure--bg & {
		width: 100%;
		grid-column: 1 / 2;
		grid-row: 1 / 2;

		.block--vSizeAuto > .block__bg &,
		.block--vSizeFixed > .block__bg & {
			height: 100%;

			@include media($columnsBreakpoint) {
				height: auto;
			}

			&--withRatio {
				&::before {
					display: none;

					@include media($columnsBreakpoint) {
						display: block;
					}
				}
			}
		}

		.block--vSizeMultiAuto > .block__bg &,
		.block--vSizeMultiFixed > .block__bg & {
			@include media($columnsBreakpoint) {
				height: 100%;
			}

			&--withRatio {
				&::before {
					@include media($columnsBreakpoint) {
						display: none;
					}
				}
			}
		}
	}


	&::before {
		.figure--preview & {
			display: none;
		}
	}



	&::after {
		@include app-colorSchemes($baseClass: '.block', $nestedSelector: ' > .block__bg &');
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;
		left: 0;
		top: 0;
		z-index: 2;

		.block--bgSoftened > .block__bg & {
			opacity: $blockBgCoverOpacity;
		}
	}
}
