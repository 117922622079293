.mainMenu {
	@include fadeOut($duration4);
	color: $colorBase;
	background-color: $colorBgBase;
	display: none;
	left: 0;
	position: relative;
	right: 0;
	top: 0;
	z-index: 3;

	@include media($columnsBreakpoint) {
		@include fadeIn();
		background-color: transparent;
		left: 0;
		top: 0;
		right: 0;
		height: $headerHeightLarge;
		display: block;
		position: fixed;
		pointer-events: none;
		z-index: 5;
	}


	&.js-fixed {
		position: fixed;
	}

	&.js-beforeActive {
		display: block;
		will-change: opacity, visibility;
	}

	&.js-duringActive {
		@include fadeIn();
	}


	&::before {
		background-color: transparentize($colorBgInverted, 0.2);
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		content: '';
		position: fixed;
		z-index: 0;

		@include media($columnsBreakpoint) {
			display: none;
		}
	}


	&__content {
		// @include app-container($onlyPadding: true);
		background-color: $colorBgBase;
		padding-top: ($headerHeight + $base * 12);
		position: relative;
		z-index: 2;

		@include media($columnsBreakpoint) {
			background-color: transparent;
			padding: 0;
		}
	}


	&__items {
		@include media($columnsBreakpoint) {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			column-gap: $base2;
		}
	}


	&__item {
		border-bottom: 1px solid $colorBorder;

		&:nth-child(2) {
			@include media($columnsBreakpoint) {
				grid-column-start: 2;
			}
		}

		@include media($columnsBreakpoint) {
			border: 0;
		}

		&--home {
			border-top: 1px solid $colorBorder;

			@include media($columnsBreakpoint) {
				display: none;
			}
		}
	}

	&__link {
		@include font(menuItem);
		color: currentColor;
		display: block;
		padding: $base2 $base;
		pointer-events: auto;
		text-decoration: none;

		@include media($columnsBreakpoint) {
			display: inline-block;
			padding: 0;
			line-height: $headerHeightLarge;
		}

		&.js-currentLink {
			font-weight: bold;
		}
	}
}
