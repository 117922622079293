.main {
	position: relative;
	z-index: 2;
	// text-align: right;

	&.js-covered {
		@include app-covered();
	}


	&__menuToggler {
		display: none;
		height: 0;
		line-height: 0;
		padding-right: $blockHorizontalPaddingDefaultSmall;
		position: sticky;
		right: 0;
		top: 0;
		z-index: 100;	// it has to be bigger than the amount of layouts inside the page. 100 seems a safe enough value

		@include media($columnsBreakpoint) {
			padding-right: $blockHorizontalPaddingDefaultLarge;
		}

		.body--headerTypeStatic & {
			@include media($mediumBreakpoint) {
				display: block;
			}
		}
	}
}
