.button {
	@include button($base);
	@include font(button);
	border-color: currentColor;
	border-style: solid;
	border-width: $buttonBorderSize;
	padding: $inputPaddingVertical $inputPaddingHorizontal;
	text-align: center;
	color: inherit;

	&:disabled,
	&.js-disabled {
		cursor: default;
		opacity: $opacityInputDisabled;
		pointer-events: none;
	}

	.block--slide & {
		user-select: none;
	}

	&--closeSearchBox {
		@include ease(opacity visibility, $duration4);
		@include fadeOut();
		border: 0;
		padding: 0;
		height: $base2;
		width: $base2;
		position: absolute;
		right: $base2;
		top: 50%;
		transform: translateY(-50%);

		.header.js-searchActive & {
			@include fadeIn();
		}


		&::before {
			display: none;
		}
	}

	&--plain {
		font-size: inherit;
		line-height: inherit;
		padding: 0;
		border: 0;
		text-align: left;
		display: inline-block;

		&::before {
			display: none;
		}
	}

	&--default {
		@include ease(border-color color background-color, $duration2);

		&:focus {
			border-color: $colorFocus;
			outline: none;
		}

		.block--colorSchemeDefault &,
		.block--colorSchemeAlternative & {
			@include onHover() {
				background-color: $colorBgInverted;
				border-color: $colorBgInverted;
				color: $colorInverted;
			}
		}

		.block--colorSchemeInverted & {
			@include onHover() {
				background-color: $colorBgBase;
				border-color: $colorBgBase;
				color: $colorBase;
			}
		}

		.block--submenu & {
			@include ease(opacity, $duration2);
			position: relative;
			height: $base6;
			border: 0;
			padding: 0;
			width: 100%;

			&.js-currentLink {
				font-weight: bold;
				pointer-events: none;
			}

			@include onHover() {
				background-color: transparent;
				color: currentColor;
			}

			@include media($mediumBreakpoint) {
				height: $headerHeight;
				width: auto;
			}
		}

		// .block--submenu:hover & {
		// 	&:not(:hover):not(:active) {
		// 		opacity: 0.8;
		// 	}
		// }
	}

	&--alternative {
		@include ease(border-color color background-color, $duration2);
		border-color: transparent;

		&:focus {
			border-color: $colorFocus;
			outline: none;
		}

		.block--colorSchemeDefault &,
		.block--colorSchemeAlternative & {
			@include onHover() {
				background-color: $colorBgButtonAlternativeHover;
				border-color: $colorBgButtonAlternativeHover;
			}
		}

		.block--colorSchemeInverted & {
			@include onHover() {
				background-color: $colorBgButtonAlternativeInvertedHover;
				border-color: $colorBgButtonAlternativeInvertedHover;
			}
		}
	}

	&--seamless {
		@include font(buttonSeamless);
		@include ease(border-color, $duration2);
		// include here link specific style
		background-color: transparent;
		border-color: transparent;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: $baseHalf;

		@include onHover() {
			border-bottom-color: currentColor;
		}

		&:focus {
			border-bottom-color: $colorFocus;
			outline: none;
		}
	}


	&--menuToggler {
		@include font(menuItem);
		border: 0;
		padding: $base2 0;
		line-height: 1;
	}

	&--regionsToggler {
		@include font(base);
		border: 0;
		padding: 0;
		text-transform: uppercase;
	}

	&--productsOverviewSizeSelector {
		border: 0;
		padding: 0;
	}

	&--customSelectToggler {
		@include font(customSelect);
		font-weight: bold;
		border-color: $colorCustomSelectOptionBorder;
		padding: $base * 1.5;

		.block--colorSchemeAlternative & {
			border-color: $colorCustomSelectOptionBorderAlternative;
		}


		.listFilters & {
			width: 100%;
			text-align: left;
			border-left-width: 0;
			border-right-width: 0;

			@include media('>=tileSizeSmall') {
				border-left-width: $buttonBorderSize;
				border-right-width: $buttonBorderSize;
			}
		}

		.listFilters .customSelect:nth-child(2n + 1):not(:last-child) & {
			@include media('>=tileSizeSmall') {
				border-right-width: 0;
			}
		}

		.listFilters .customSelect:nth-child(2n + 1):nth-last-child(2) & {
			@include media('>=tileSizeSmall') {
				border-bottom-width: $buttonBorderSize;
			}
		}

		.listFilters .customSelect:not(:last-child) & {
			border-bottom-width: 0;

			@include media('>tileSizeMedium') {
				border-bottom-width: $buttonBorderSize;
				border-right-width: 0;
			}
		}
	}

	&--customSelectReset {
		@include font(customSelect);
		background-color: $colorBgCustomSelectReset;
		color: $colorCustomSelectReset;
		border: 0;
		display: flex;
		font-weight: bold;
		min-width: 100%;
		padding: ($base * 1.5) $base2;
		text-align: center;
		white-space: nowrap;
	}

	&--listFiltersTag {
		@include font(listFiltersTag);
		color: $colorFilterTag;
		background-color: $colorBgFilterTag;
		border: 0;
		border-radius: $base * 1.7;
		padding: $baseHalf ($base * 1.5);
		// the margin is taken into account in the container with corresponding negative margins
		margin-top: $base;
		margin-left: $base;

		.block--colorSchemeAlternative & {
			color: $colorFilterTagAlternative;
			background-color: $colorBgFilterTagAlternative;
		}

		&.js-hidden {
			display: none;
		}
	}

	&--productsOverviewToggleFamilyProducts {
		@include font(productsOverviewToggleFamilyProducts);
		align-items: center;
		border: 0;
		display: none;
		justify-content: space-between;
		position: relative;
		width: 100%;
		z-index: 4;

		@include media($tileSizePartialBreakpoint) {
			.filterableList--productsOverview.js-largeTiles & {
				display: flex;
			}
		}

		@include media($tileSizeFullBreakpoint) {
			display: flex;
		}


		// balance the two items on the right, so the label is centered
		&::before {
			content: ' ';
			display: block;
			width: $base4;
		}
	}


	&--slideshowDirection {
		border: 0;
		padding: $base * 1.5;
		background-color: $colorBgSlideshowDirection;
		color: $colorSlideshowDirection;
		line-height: 0;
		pointer-events: auto;

		.slideshow__slides[data-slide-color-scheme="inverted"] ~ .slideshowNavigation--arrows & {
			background-color: $colorBgSlideshowDirectionInverted;
			color: $colorSlideshowDirectionInverted;

			.block--productModels & {
				background-color: transparent;
			}
		}

		.block--productModels & {
			background-color: transparent;
		}
	}

	&--slideshowDirectionPrev {
		.block--productModels & {
			padding-left: 0;
		}
	}

	&--slideshowDirectionNext {
		.block--productModels & {
			padding-right: 0;
		}
	}

	&--slideshowTrigger {
		border-left: 0;
		border-right: 0;
		border-top: 0;
		border-bottom-color: transparent;
		padding: 0 $base $baseHalf $base;
		width: 100%;

		.slideshowNavigation--noText & {
			border: 0;
			height: $base2;
			opacity: 0.3;
			padding: 0;
			font-size: 0;
			line-height: 0;
			text-indent: -10000px;
		}


		&::after {
			@include ease(opacity, $duration2);
			background-color: currentColor;
			opacity: $slideshowTriggerNotCurrentOpacity;
			content: '';
			height: 1px;
			position: absolute;
			left: 0;
			top: 100%;
			width: 100%;

			.slideshowNavigation--noText & {
				top: 50%;
				height: ($base * 0.3);
				margin-top: -($base * 0.3 / 2);
			}
		}

		&.js-current::after {
			opacity: 1;
		}
	}

	&--link {
		@include app-link();
		// include here link specific style
		background-color: transparent;
		border-color: transparent;
		padding: 0;
		min-width: 0;
		text-align: left;
	}

	&--pagination {
		@include font(menuItem);
		min-width: $base6;
	}

	&--paginationArrow {
		// &::before {
		// 	display: none;
		// }
	}

	&--paginationCurrent,
	&--paginationSeparator {
		pointer-events: none;
		color: currentColor;
		font-weight: bold;
	}

	&--prominent {
		@include media($columnsBreakpoint) {
			min-width: ($buttonMinWidth * 2 + $base2);
		}
	}

	&--search {
		display: none;
	}

	&--secondary {
		background-color: transparent;
	}

	&--trackersAcceptAll,
	&--trackersRejectAll {
		width: staticColumnSize(1, 2, $gutter);

		.trackers.js-confirmMode & {
			display: none;
		}
	}

	&--trackersOptions {
		text-align: center;
		width: staticColumnSize(1, 1, $gutter);
	}

	&--trackersSave {
		width: staticColumnSize(1, 1, $gutter);
	}

	&--trackersOk {
		width: staticColumnSize(1, 1, $gutter);

		.trackers:not(.js-confirmMode) & {
			display: none;
		}
	}

	.booking & {
		min-width: 0;
		width: 100%;
	}

	// buttons inside forms
	.form & {
		margin-bottom: $base2;
		margin-left: 0;
		margin-top: $base2;
		min-width: $buttonMinWidth;
		padding: $inputPaddingVertical $inputPaddingHorizontal;
	}

	.form > &:first-child {
		margin-top: 0;
	}

	.form__buttons > & {
		margin-left: $base2;
		margin-top: 0;
	}

	.field--search .field__input--text + & {
		flex-grow: 0;
		flex-shrink: 1;
		margin-bottom: 0;
		margin-left: $base2;
		margin-top: 0;
		min-width: 0;

		@include media($columnsBreakpoint) {
			min-width: $buttonMinWidth;
		}
	}


	.field__fields--grid & {
		width: auto;

		@include media($columnsBreakpoint) {
			align-self: flex-end;
			min-width: none;
			width: dynamicColumnSize(1, 3, $gutter);
		}
	}


	&__icon {
		.button--closeSearchBox & {
			width: 100%;
			height: 100%;
		}

		.button--paginationArrow & {
			position: relative;
			width: $base2;
		}

		.button--paginationPrev & {
			[data-type="svg"] {
				transform: rotate(180deg);
			}
		}



		.button--regionsToggler & {
			.intlSelector & {
				width: $base2;
				height: $base2;
			}
		}


		.button--menuToggler & {
			// margin-left: 0;
			height: $menuTogglerHeight;

			// @include media($columnsBreakpoint) {
			// 	margin-left: $buttonIconDistance;
			// }
		}

		.button--customSelectToggler & {
			@include ease(transform, $duration2);
			position: relative;
		}

		.button--customSelectToggler.js-toggled & {
			transform: rotate(180deg);
		}

		.button--productsOverviewToggleFamilyProducts & {
			@include ease(transform, $duration2);
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex-grow: 0;
			height: $base;
			line-height: 0;
			transform: rotate(-90deg);
			position: relative;
			margin: 0;

			.listTile.js-opened & {
				transform: rotate(90deg);
			}


			[data-type="svg"] {
				width: 100%;
				height: auto;
			}
		}

		.filterableList--productsOverview.js-largeTiles &--small,
		.filterableList--productsOverview:not(.js-largeTiles) &--large {
			display: none;
		}


		.button--slideshowDirection & {
			height: $base2;
			width: $base2;
		}

		.button--slideshowDirectionPrev & {
			transform: rotate(180deg);
		}
	}


	&__label {
		.button--trackersDetails.js-toggled &--show,
		.button--trackersDetails:not(.js-toggled) &--hide,
		.trackers.js-expanded .button--trackersOptions &--show,
		.trackers:not(.js-expanded) .button--trackersOptions &--hide {
			display: none;
		}


		.button--productsOverviewToggleFamilyProducts &--main {
			flex-grow: 1;
		}

		.button--productsOverviewToggleFamilyProducts &--counter {
			flex-grow: 0;
			margin-right: $base;
			text-align: right;
			width: $base2;
		}

		.button--menuToggler.js-toggled &--default {
			display: none;
		}

		.button--menuToggler:not(.js-toggled) &--toggled {
			display: none;
		}
	}
}
