.header {
	--floating: 1;
	color: inherit;
	left: 0;
	height: 0;
	position: fixed;
	width: 100%;
	z-index: 4;

	// &--static,
	// &--staticNoToggler {
	// 	--floating: 0;
	// }

	// &:not(&--floating) {
	// 	@include media($mediumBreakpoint) {
	// 		--floating: 0;
	// 	}
	// }


	&__content {
		@include ease(background-color transform, $duration3);
		@include app-container($onlyPadding: true);
		background-color: transparent;
		border-bottom: 1px solid $colorBorder;
		align-items: center;
		display: flex;
		flex-direction: row;
		height: $headerHeight;
		justify-content: space-between;
		padding-top: $headerTopPadding;
		position: relative;
		pointer-events: none;

		@include media($columnsBreakpoint) {
			height: $headerHeightLarge;
		}



		.header:not(.js-coveredByMenu):not(.js-searchActive).js-collapsed & {
			// transform: translateY(-100%);
		}


		&::before {
			@include ease(opacity, $duration2);
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			z-index: 0;

			@each $schemeName, $value in $colorBgHeaderOverContent {
				$selectors: '.body--colorScheme' + ucFirst($schemeName) + '  &';

				#{$selectors} {
					background-color: $value;
				}
			}

			.header:not(.js-coveredByMenu).js-overContent & {
				opacity: 1;
			}
		}
	}


	&__homeLink {
		pointer-events: auto;
		color: currentColor;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		position: relative;
		text-decoration: none;
	}


	&__menu {
		@include ease(opacity visibility, $duration4);
		@include font(header);
		align-items: center;
		display: flex;
		justify-content: flex-end;

		.header.js-searchActive & {
			@include fadeOut();
		}
	}


	&__menuItem {
		pointer-events: auto;

		&:not(:first-child) {
			margin-left: $base2;

			@include media($mediumBreakpoint) {
				margin-left: $base3;
			}
		}

		&--menuToggler {
			line-height: 0;

			@include media($columnsBreakpoint) {
				display: none;
			}

			.header--static & {
				@include media($mediumBreakpoint) {
					visibility: hidden;
				}
			}

			.header--static.js-coveredByMenu & {
				@include media($mediumBreakpoint) {
					visibility: inherit;
				}
			}
		}

		&--regionsToggler {
			@include ease(opacity visibility, $duration4);

			.header.js-coveredByMenu & {
				@include fadeOut();
			}
		}
	}


	&__search {
		@include ease(right width padding-right, $duration4);
		align-items: stretch;
		display: flex;
		height: 100%;
		padding: 0 $base2;
		pointer-events: auto;
		position: absolute;
		right: $base * 11;
		top: 0;
		width: calc(100% - #{$base * 10 + $base7 + $base4});

		@include media($mediumBreakpoint) {
			@include ease(right width, $duration6);
			right: $base * 14;
			width: $base * 16;
		}

		.header.js-coveredByMenu & {
			display: none;
		}

		.header.js-searchActive & {
			padding-right: $base6;
			right: 0;
			width: 100%;
		}
	}


	&__title {
		@include ease(opacity visibility, $duration4);
		height: 100%;
		pointer-events: auto;

		.header.js-searchActive & {
			@include fadeOut();
		}
	}
}
