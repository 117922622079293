.preview {
	@include ease(opacity visibility, $duration4);
	background-color: $colorBgBase;
	max-width: 100%;
	margin-left: 1px;
	margin-right: 1px;
	position: relative;
	// overflow: hidden;

	display: flex;
	flex-direction: column;

	&--mini {
		flex-grow: 1;
		min-height: (100vh / 3);

		.block--coverHome & {
			min-height: auto;
		}
	}

	&:not(&--mini) {
		min-height: $previewMinHeight;
	}

	&:not(&--mini).preview--withFigure {

		@include media($columnsBreakpoint) {
			display: grid;
			column-gap: 1px;
			grid-template-columns: 1fr 1fr;
			grid-template-areas: 'content	figure';
		}

		.block--previewLayouts .layout__column--colSpan4 & {
			@include media($columnsBreakpoint) {
				grid-template-columns: 1fr;
				grid-template-areas: 'content';
			}
		}

		.block--previewLayouts .layout__column--colSpan12 & {
			@include media($columnsBreakpoint) {
				grid-template-columns: 2fr 1fr;
			}
		}

		.block--highlightPage & {
			@include media($columnsBreakpoint) {
				grid-template-columns: 2fr 1fr;
			}
		}

		&.preview--figureFirst {
			flex-direction: column-reverse;

			@include media($columnsBreakpoint) {
				grid-template-areas: 'figure	content';
			}

			.block--previewLayouts .layout__column--colSpan4 & {
				@include media($columnsBreakpoint) {
					grid-template-columns: 1fr;
					grid-template-areas: 'content';
				}
			}

			.block--previewLayouts .layout__column--colSpan12 & {
				@include media($columnsBreakpoint) {
					grid-template-columns: 1fr 2fr;
				}
			}

			.block--highlightPage & {
				@include media($columnsBreakpoint) {
					grid-template-columns: 1fr 2fr;
				}
			}

			.block--filterablePersonList & {
				display: grid;
				grid-template-columns: 1fr 2fr;
				grid-template-areas: 'figure	content';
				min-height: 0;


				@include media($columnsBreakpoint) {
					grid-template-columns: 1fr 3fr;
				}
			}
		}


		.filterableList & {
			@include media($columnsBreakpoint) {
				grid-column: span 2;
			}
		}
	}

	.block--searchResults &--person.preview--withFigure {
		@include media($columnsBreakpoint) {
			grid-template-columns: 1fr 3fr;
		}
	}

	.block--coverHome & {
		background-color: transparent;
	}

	&.js-duringHidden {
		opacity: 0;
		visibility: hidden;
	}

	&.js-hidden {
		display: none;
	}

	.block--workgroupList &--researchArea {
		background-color: $colorBgInverted;
		color: $colorInverted;
		margin-left: 0;
		margin-right: 0;
	}

	.block--researchAreaList & {
		@include app-rowLines();
		margin-right: (100% / 3);

		@include media($columnsBreakpoint) {
			margin-right: 0;
		}
	}


	.block--previewLayouts & {
		max-width: 100vw;
		@include app-rowLines();
	}


	.block--searchResults &,
	.block--workgroupList &,
	.block--filterableWorkgroupList &,
	.block--filterablePublicationList &,
	.block--filterablePersonList &,
	.block--filterableEventList & {
		@include app-rowLines();
	}

	.listGrid & {
		@include media($columnsBreakpoint) {
			@include app-rowLines();
		}


		// .block--workgroupList & {
		// }
	}


	&__author {
		display: inline;

		&:not(:last-child)::after {
			content: ',';
		}
	}


	&__authorLink {
		color: inherit;
		text-decoration: none;

		@include onHover() {
			text-decoration: underline;
		}
	}


	&__authors {
		@include font(base);
		flex-grow: 1000;
		margin-top: $base * 1.5;
		order: 3;
	}


	&__content {
		padding: $base;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		grid-area: content;
	}


	&__category {
		@include font(category);
		order: 1;
		margin-bottom: $base;

		.preview--mini & {
			@include media($columnsBreakpoint) {
				margin-bottom: $base2;
			}
		}

		.preview--mini.preview--researchArea & {
			@include media($columnsBreakpoint) {
				margin-bottom: $base3;
			}
		}
	}


	&__date {
		@include font(category);
		display: none;
		order: 5;

		.preview--mini:not(.preview--workgroup):not(.preview--researchArea) & {
			display: block;
		}
	}


	&__description {
		@include font(base);
		margin-top: $base * 1.5;
		order: 4;

		.preview--mini & {
			display: none;
		}

		.preview--person & {
			display: none;

			@include media($columnsBreakpoint) {
				display: block;
			}

			.block--searchResults & {
				@include media($columnsBreakpoint) {
					display: none;
				}
			}
		}
	}


	&__figure {
		position: relative;
		grid-area: figure;
		margin: $base;

		.preview--mini & {
			display: none;
		}

		.block--previewLayouts .layout__column--colSpan4 & {
			@include media($columnsBreakpoint) {
				display: none;
			}
		}


		&--placeholder {
			@include minAspectRatio($imagePlaceholderRatio);
			align-self: start;
			color: inherit;
			border: 0;
			text-decoration: none;
			display: block;
		}
	}


	&__figurePlaceholder {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border: 1px solid $colorBorder;
		overflow: hidden;


		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 200%;
			border-bottom: 1px solid $colorBorder;
			transform-origin: 0 0;
			transform: rotate($imagePlaceholderLinesAngle);
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			top: auto;
			width: 200%;
			border-bottom: 1px solid $colorBorder;
			transform-origin: 0 0;
			transform: rotate(-$imagePlaceholderLinesAngle);
		}
	}


	&__link {
		@include font(category);
		@include ease(border-bottom-color, $duration2);
		align-self: flex-start;
		border-bottom: 1px solid $colorBorder;
		color: currentColor;
		margin-top: $base * 1.5;
		margin-bottom: $baseHalf;
		order: 6;
		text-decoration: none;

		@include onHover() {
			border-bottom-color: transparent;
		}

		.preview--mini & {
			border: 0;
			outline: 0;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			text-indent: -10000px;
			color: transparent;
			font-size: 0;
			line-height: 0;
			display: block;
			margin: 0;

			@include onHover() {
				border: 0;
			}
		}


		.preview--mini &::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
		}


		// .preview.preview--researchArea & {
		// 	display: none;
		// }
	}


	&__title {
		@include font(title);
		order: 2;
		flex-grow: 1;

		.preview--titleStyleAlternative & {
			@include font(titleAlternative);
		}

		.preview--mini & {
			@include font(titleMini);
		}

		.preview--mini.preview--researchArea & {
			@include font(titleMiniResearchArea);
		}
	}


	&__titleLink {
		color: inherit;
		text-decoration: none;
	}
}
